import {
    RankingInfo,
    rankItem,
} from '@tanstack/match-sorter-utils'
import "@tanstack/react-table";
import { FilterFn } from '@tanstack/react-table';

declare module '@tanstack/react-table' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

export const fuzzyFilter: FilterFn<unknown> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({
        itemRank,
    })
    return itemRank.passed
}